import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Config } from '../config';

const baseUrl: string = new Config().getBaseURL();
@Injectable({
  providedIn: 'root'
})
export class CibilService {

  constructor(private http: HttpClient) { }

  getUserConsent(companyId:string, verificationToken:string,clientKey: string, requestKey: string, payload:any){
    return this.http.post(`${baseUrl}/cibil/${companyId}/${verificationToken}/${clientKey}/${requestKey}/cibilConsent`,payload)
  }

  getAuthenticationQuestions(companyId:string, verificationToken:string,clientKey: string, requestKey: string):Observable<any>{
    return this.http.get(`${baseUrl}/cibil/${companyId}/${verificationToken}/${clientKey}/${requestKey}/getAuthQues`)
  }

  verifyAuthAnswers(companyId:string, verificationToken:string, clientKey: string, requestKey:string,payload:any){
    return this.http.post(`${baseUrl}/cibil/${companyId}/${verificationToken}/${clientKey}/${requestKey}/verifyAuthAnswers`, payload)
  }

  getCibilData(companyId: string){
    return this.http.get(`${baseUrl}/cibil/${companyId}/getStatus`)
  }
}