import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { Config } from '../config';
import { PROVIDE_FEEDBACK,PROVIDE_OPEN_FEEDBACK } from './all.api'

const baseUrl = new Config().getBaseURL() + '/userFeedback';

@Injectable({
  providedIn: 'root'
})
export class FeedbackService {
  queryToggle = new BehaviorSubject<boolean>(false)
  constructor(private http: HttpClient) { }

  sendFeedback(data): Observable<any> {
    return this.http.post(PROVIDE_FEEDBACK, data)
  }

  sendOpenFeedback(data): Observable<any> {
    return this.http.post(PROVIDE_OPEN_FEEDBACK, data)
  }
}
