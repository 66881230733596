import {Injectable} from '@angular/core'

@Injectable({
    providedIn: 'root'
})

export class HelperService {
    fileName (string: string){
        let filename = `${Date.now()}_${string.replace(/\s/g, '_')}`
        filename = filename.replace(/[&\/\\#, +()$~%'":*?<>{}]/g, '')
        const fullPath = `${filename}`
        console.log('File Name = ', fullPath)
        return fullPath
      }
}